import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_Protocol = _resolveComponent("Protocol")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_descriptions, {
          title: "司机福利",
          bordered: ""
        }),
        _createVNode(_component_Protocol, { "protocol-type": "courier_welfare" })
      ]),
      _: 1
    })
  ]))
}