
// 司机福利
import {defineComponent} from "vue";
import Protocol from '@/components/ProtocolSet.vue'

interface IState {
  detail: string
  loading: boolean,
}

export default defineComponent({
  name: "Welfare",
  components: {Protocol},
  setup() {

    return {

    }
  }
})
